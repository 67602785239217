import React, { ReactElement, useState } from "react";
import {
  Anchortext,
  CenterMenu,
  Container,
  ContatcDetialsWrapper,
  Dropdown,
  HeaderWrapper,
  LogoWrapper,
  MobileHeader,
  MobileNavHeader,
  Navbar,
  OrderList,
} from "./subComponents";
import { HeaderProsp } from "./types";
import brand_image from "../../assests/brand.png";
import { useNavigate } from "react-router";
import MENUS_TYPE from "../../const/menus";
import Drawer from "../Drawer";
import MobileSidemenu from "./mobile";

const Header = ({
  scrollPosition,
  background,
  height,
  position,
  color,
}: HeaderProsp): ReactElement => {
  const navigate = useNavigate();
  const [showMenu, setshowMenu] = useState(false);
  const [showMobile, setshowMobile] = useState(false);
  return (
    <>
      <Drawer
        show={showMobile}
        onClose={() => {}}
        onOpen={() => {}}
        component={
          <MobileSidemenu
            onClose={() => {
              setshowMobile(false);
            }}
          />
        }
      />
      <HeaderWrapper>
        <ContatcDetialsWrapper>
          <span>
            <p>
              <i className="bx bxs-location-plus"></i>760 Panorama Ct, Aurora,
              IL -60502, US
            </p>
            <p>
              <i className="bx bx-time"></i>Daily : 8.00 am to 10.00 pm
            </p>
          </span>{" "}
          <span>
            <p>
              {" "}
              <i className="bx bx-phone"></i>+1 (630) 364 3926
            </p>
            <p>
              {" "}
              <i className="bx bx-envelope"></i>Info@nammakitchen.us
            </p>
          </span>
        </ContatcDetialsWrapper>
        <Navbar
          position={position}
          height={height}
          background={background}
          scrollPosition={scrollPosition}
        >
          <Container>
            <LogoWrapper>
              <img src={brand_image} alt="res_logo" />
            </LogoWrapper>
            <CenterMenu>
              <OrderList>
                <li
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <Anchortext>Home</Anchortext>
                </li>{" "}
                <li
                  onMouseEnter={() => {
                    setshowMenu(true);
                  }}
                  onMouseLeave={() => {
                    setshowMenu(false);
                  }}
                >
                  <Anchortext>MENUS</Anchortext>
                  {showMenu && (
                    <Dropdown>
                      <span>
                        <p
                          onClick={() => {
                            navigate("/menu-lists", {
                              state: { menu_name: MENUS_TYPE.breakFast },
                            });
                          }}
                        >
                          Breakfast
                        </p>
                        <p
                          onClick={() => {
                            navigate("/menu-lists", {
                              state: { menu_name: MENUS_TYPE.mainCourse },
                            });
                          }}
                        >
                          Main Courses
                        </p>
                        <p
                          onClick={() => {
                            navigate("/menu-lists", {
                              state: { menu_name: MENUS_TYPE.snacks },
                            });
                          }}
                        >
                          Snacks/Appetizer
                        </p>
                        <p
                          onClick={() => {
                            navigate("/menu-lists", {
                              state: { menu_name: MENUS_TYPE.desserst },
                            });
                          }}
                        >
                          Desserts
                        </p>
                        <p
                          onClick={() => {
                            navigate("/menu-lists", {
                              state: { menu_name: MENUS_TYPE.drinks },
                            });
                          }}
                        >
                          Drinks
                        </p>
                      </span>
                    </Dropdown>
                  )}
                </li>{" "}
                <li
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  <Anchortext color={color}>ABOUT US</Anchortext>
                </li>{" "}
                <li
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  <Anchortext>CONTACT</Anchortext>
                </li>
              </OrderList>
            </CenterMenu>
            <div></div>
          </Container>
        </Navbar>
      </HeaderWrapper>
      <MobileHeader>
        <MobileNavHeader>
          <i
            className="bx bx-menu-alt-left"
            onClick={() => {
              setshowMobile(!showMenu);
            }}
          ></i>{" "}
          <LogoWrapper>
            <img src={brand_image} alt="res_logo" />
          </LogoWrapper>
        </MobileNavHeader>
      </MobileHeader>
    </>
  );
};

export default Header;
