import { MenuList } from "./types";
import Image from "../../assests/menu/menu-image-1.jpg";
import sambar from '../../assests/maincourse/sambar.jpg'
import sambarrice from '../../assests/maincourse/sambar-rice.jpg'
import rasam from '../../assests/maincourse/rasam.jpg'
import vathakulambu from '../../assests/maincourse/vatha-kulambu.jpg'
import aviyal from '../../assests/maincourse/aviyal.jpg'
import kootu from '../../assests/maincourse/kootu.jpg'
import poriyal from '../../assests/maincourse/poriyal.jpg'
import kuzhambu from '../../assests/maincourse/kuzhambu.jpg'
import bisi from '../../assests/maincourse/bisi-bella-bath.jpg'
import pallipalayam from '../../assests/maincourse/pallipalayam-chicken.jpg'
import puliyodarai from '../../assests/maincourse/puliyodarai.jpg'
import chickenbiriyani from '../../assests/maincourse/chicken-biriyani.jpg'
import chickenchintamani from '../../assests/maincourse/chicken-chintamani.jpg'
import curdrice from '../../assests/maincourse/curd-rice.jpg'
import muttonbriyani from '../../assests/maincourse/mutton-biriyani.jpg'
import fishgravy from '../../assests/maincourse/fish-gravy.jpg'
import chickengravy from '../../assests/maincourse/chicken-gravy.jpg'
import muttongravy from '../../assests/maincourse/mutton-gravy.jpg'
//breakfast
import dosa from '../../assests/breakfast/dosa.jpg'
import idly from '../../assests/breakfast/idly.jpg'
import vada from '../../assests/breakfast/medhu-vada.jpg'
import pineapple from '../../assests/breakfast/pineapple.jpg'
import upma from '../../assests/breakfast/upma.jpg'
import uttappam from '../../assests/breakfast/uttappam.jpg'
import pongal from '../../assests/breakfast/ven-pongal.jpg'
//snacks
import allo from '../../assests/snacks/aloo-bonda.jpg'
import bajji from '../../assests/snacks/bajji.jpg'
import chicken65 from '../../assests/snacks/chcken-65.jpg'
import chickenman from '../../assests/snacks/chicken-manchurian.jpg'
import gobi65 from '../../assests/snacks/gobi-65.jpg'
import gopiman from '../../assests/snacks/gopi-manchurian.jpg'
import muttonc from '../../assests/snacks/mutton-chukka.jpg'
import onionpakkoda from '../../assests/snacks/onion-pakkoda.jpg'
import samosa from '../../assests/snacks/samosa.jpg'
import sundal from '../../assests/snacks/sundal.jpg'
//deserts
import ashoka from '../../assests/desserts/ashoka-halwa.jpg'
import gulab from '../../assests/desserts/gulab-jamun.png'
import nannari from '../../assests/desserts/nannari-sarbat.png'
import paayasam from '../../assests/desserts/paayasam.jpg'
import rasa from '../../assests/desserts/rasa-malai.jpg'
import rose from '../../assests/desserts/rose-milk.png'
import tea from '../../assests/desserts/tea-coffee.jpg'


const breakFast: Array<MenuList> = [
  {
    id: 1,
    name: "Idli",
    image: idly,
    description: "Steamed rice cakes",
    price: 30,
  },
  {
    id: 2,
    name: "Dosa",
    image: dosa,
    description: "Thin, crispy rice crepes",
    price: 40,
  },
  {
    id: 3,
    name: "Uthappam",
    image: uttappam,
    description: "Thick rice pancakes with toppings",
    price: 40,
  },
  {
    id: 4,
    name: "Vada",
    image: vada,
    description: "Savory fried doughnuts made from lentils",
    price: 50,
  },
  {
    id: 5,
    name: "Pongal",
    image: pongal,
    description: " Savory rice and lentil dish",
    price: 70,
  },
  {
    id: 6,
    name: "Kichadi/Upma",
    image: upma,
    description: "Semolina porridge with vegetables",
    price: 60,
  },
  {
    id: 7,
    name: "Pineapple Kesari",
    image: pineapple,
    description: "",
    price: 60,
  },
];

const mainCourse: Array<MenuList> = [
  {
    id: 8,
    name: "Sambar",
    image: sambar,
    description: "",
    price: 50,
  },
  {
    id: 9,
    image: rasam,
    name: "Rasam",
    description: "",
    price: 50,
  },
  {
    id: 10,
    name: "Vathal kulambu",
    image: vathakulambu,
    description: "",
    price: 50,
  },
  {
    id: 11,
    image: aviyal,
    name: "Avial",
    description: "",
    price: 50,
  },
  {
    id: 12,
    name: "Kootu",
    image: kootu,
    description: "",
    price: 50,
  },
  {
    id: 13,
    name: "Poriyal",
    description: "",
    image: poriyal,
    price: 50,
  },
  {
    id: 14,
    name: "Kuzhambu",
    description: "",
    image: kuzhambu,
    price: 50,
  },
  {
    id: 15,
    name: "Bisi Bele Bath",
    image: bisi,
    description: "",
    price: 50,
  },
  {
    id: 16,
    name: "Puliyodarai",
    description: "",
    image: puliyodarai,
    price: 50,
  },
  {
    id: 17,
    name: "Sambar Rice",
    description: "",
    image: sambarrice,
    price: 50,
  },
  {
    id: 18,
    name: "Curd Rice",
    image: curdrice,
    description: "",
    price: 50,
  },
  {
    id: 19,
    name: "Chicken Biryani",
    description: "",
    image: chickenbiriyani,
    price: 50,
  },
  {
    id: 20,
    name: "Mutton Biryani",
    image: muttonbriyani,
    description: "",
    price: 50,
  },
  {
    id: 21,
    name: "Fish Gravy",
    description: "",
    image: fishgravy,
    price: 50,
  },
  {
    id: 22,
    name: "All type of Chicken gravy",
    description: "",
    image: chickengravy,
    price: 50,
  },
  {
    id: 23,
    name: "All type of Mutton Gravy",
    image: muttongravy,
    description: "",
    price: 50,
  },
  {
    id: 25,
    name: "Palipalayam Chicken",
    image: pallipalayam,
    description: "",
    price: 50,
  },
  {
    id: 26,
    name: "Chicken Chinthamani",
    image: chickenchintamani,
    description: "",
    price: 50,
  },
  {
    id: 26,
    image: Image,
    name: "All varieties of chicken/Mutton/Fish based on your request",
    description: "",
    price: 50,
  },
];

const snacks: Array<MenuList> = [
  {
    id: 27,
    image: sundal,
    name: "Beach Sundal",
    description: "",
    price: 50,
  },
  {
    id: 28,
    image: bajji,
    name: "Bajji",
    description: "",
    price: 50,
  },
  {
    id: 29,
    image: samosa,
    name: "Samosa",
    description: "",
    price: 50,
  },
  {
    id: 30,
    image: onionpakkoda,
    name: "Onion Pakoda",
    description: "",
    price: 50,
  },
  {
    id: 31,
    image: chicken65,
    name: "Chicken 65",
    description: "",
    price: 50,
  },
  {
    id: 32,
    image: chickenman,
    name: "Chicken Manchurian",
    description: "",
    price: 50,
  },
  {
    id: 33,
    image: gobi65,
    name: "Gobi 65",
    description: "",
    price: 50,
  },
  {
    id: 34,
    image: gopiman,
    name: "Gobi Manchurian",
    description: "",
    price: 50,
  },
  {
    id: 35,
    image: allo,
    name: "Alu Ponda",
    description: "",
    price: 50,
  },
  {
    id: 36,
    image: muttonc,
    name: "Mutton Chukka",
    description: "",
    price: 50,
  },
];

const desserts: Array<MenuList> = [
  {
    id: 37,
    image: paayasam,
    name: "Payasam/Paruppu payasam/Sabu dana payasam/Milk payasam",
    description: "",
    price: 50,
  },
  {
    id: 38,
    image: pineapple,
    name: "Kesari/Pineapple Kesari/ Fruit Kesari",
    description: "",
    price: 50,
  },
  {
    id: 39,
    image: ashoka,
    name: "Ashoka Halwa",
    description: "",
    price: 50,
  },
  {
    id: 40,
    image: rasa,
    name: "Rasa Malai",
    description: "",
    price: 50,
  },
  {
    id: 41,
    image: gulab,
    name: "Gulab Jamun",
    description: "",
    price: 50,
  },
];

const drinks: Array<MenuList> = [
  {
    id: 42,
    image: nannari,
    name: "Nanri Sarbat",
    description: "",
    price: 50,
  },
  {
    id: 43,
    image: rose,
    name: "Rose milk",
    description: "",
    price: 50,
  },
  {
    id: 44,
    image: tea,
    name: "Tea /Coffee",
    description: "",
    price: 50,
  },
];

export { breakFast, mainCourse, snacks, desserts, drinks };
