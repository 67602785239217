import React, { ReactElement } from "react";
import {
  PageWrapper,
  ImageBannerContainer,
  ImageOpacityReducer,
  Header,
  BannerWrapper,
  SectionContainer,
  Movetop,
} from "../../components";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import seperator_img from "../../assests/separator.png";
import banner_image from "../../assests/banner_img/menu_banner.jpg";
import { fontfamily, colors } from "../../const/theme";
import { MenuCardContainer, MenuWrapper } from "./subcomponents";
import MenuCards from "./helpers";
import Footer from "../Footer";
import MenusCourses from "./menus";
import { useLocation } from "react-router";

const Menu = (): ReactElement => {
  const loacation = useLocation();
  const menu_name = loacation?.state?.menu_name;
  Movetop();
   
  return (
    <PageWrapper>
      <Header />{" "}
      <BannerWrapper>
        <ImageBannerContainer background={banner_image}>
          <ImageOpacityReducer />
          <TitleWrapper margin="140px 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              SIP, SAVOR, & RELAX
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mb-2"
            />
            <Title
              font="75px"
              lineHeight="1"
              color="#fff"
              fontWeight={500}
              fontFamily={fontfamily.fontFamilyForum}
            >
              Our Menu
            </Title>
          </TitleWrapper>
        </ImageBannerContainer>
      </BannerWrapper>
      <MenuWrapper>
        <SectionContainer>
          <TitleWrapper margin="0">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              SPECIAL DISHES
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="50px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="1.20em"
              fontWeight={500}
              color={colors?.white}
              className="mt-4"
            >
              Delicious {menu_name?.charAt(0)?.toUpperCase() + menu_name?.slice(1)?.toLowerCase()} Menu
            </Title>
          </TitleWrapper>{" "}
          <MenuCardContainer>
            {MenusCourses({ menu_name })?.map((menu, index) => {
              return (
                <MenuCards
                  key={index}
                  dishImg={menu?.image}
                  dishName={menu?.name}
                  dishPrice={`${menu?.price}.00`}
                  dishDes={menu?.description}
                />
              );
            })}
          </MenuCardContainer>
        </SectionContainer>
      </MenuWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default Menu;
