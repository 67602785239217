import React, { ReactElement } from "react";
import {
  PageWrapper,
  RowWrapper,
  SectionContainer,
  ColumnWrapper,
  PrimaryButton,
  Header,
  BannerWrapper,ImageBannerContainer,ImageOpacityReducer
} from "../../components";
import {
  ContactContainer,
  ContactWrapper,
  InputForm,
  InputFormWrapper,
} from "./subcomponents";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import { colors, fontfamily } from "../../const/theme";
import banner_image from "../../assests/banner_img/about_banner.jpg";
import seperator_img from "../../assests/separator.png";
import Footer from "../Footer";

const Contact = (): ReactElement => { 
  return (
    <PageWrapper>
      <Header />{" "}
      <BannerWrapper>
        <ImageBannerContainer background={banner_image}>
          <ImageOpacityReducer />
          <TitleWrapper margin="140px 0 0 ">
            <Subtitles
              font="12px" 
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >ANY QUERY ?
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mb-2"
            />
            <Title
              font="75px"
              lineHeight="1"
              color="#fff"
              fontWeight={500}
              fontFamily={fontfamily.fontFamilyForum}
            >
             Contact us
            </Title>
          </TitleWrapper>
        </ImageBannerContainer>
      </BannerWrapper>
      <ContactWrapper>
        <SectionContainer>
          <ContactContainer>
            <RowWrapper>
              <ColumnWrapper lg={6}>
                <TitleWrapper textAlign="center" alignItems="center">
                  <Title
                    font="60px"
                    fontFamily={fontfamily.fontFamilyForum}
                    lineHeight="30px"
                    fontWeight={400}
                    color={colors?.white}
                    className="mt-2 mb-4"
                  >
                    Message us
                  </Title>
                  <Subtitles
                    color="rgb(167,167,167)"
                    font="16px"
                    fontFamily={fontfamily.fontFamilyDMS}
                    lineHeight="30px"
                    fontWeight={400}
                    textAlign="center"
                    className="mt-2 mb-4"
                  >
                    Have a question about the our service? <br /> We're here to
                    help, contact us today
                  </Subtitles>
                  <InputFormWrapper>
                    <InputForm placeholder="Name" />
                    <InputForm placeholder="Email" />
                    <InputForm placeholder="Subject" />
                    <InputForm
                      placeholder="Email"
                      as="textarea"
                      style={{ height: "100%", paddingTop: "25px" }}
                      rows={4}
                    />
                    <PrimaryButton
                      text="SEND YOUR MESSAGE"
                      onClick={() => {}}
                      className="mt-4"
                    />
                  </InputFormWrapper>
                </TitleWrapper>
              </ColumnWrapper>
              <ColumnWrapper></ColumnWrapper>
            </RowWrapper>
          </ContactContainer>
        </SectionContainer>
      </ContactWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default Contact;
