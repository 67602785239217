import React, { useState } from "react";
import brand_image from "../../assests/brand.png";
import { LogoWrapper, MobileNavMenu } from "./subComponents";
import { useNavigate } from "react-router";
import MENUS_TYPE from "../../const/menus";

interface MobileSidemenuProps {
  onClose: () => void;
}

const MobileSidemenu = ({ onClose }: MobileSidemenuProps) => {
  const navigate = useNavigate();
  const [showChild, setshowChild] = useState(false);
  return (
    <MobileNavMenu>
      <span className="close" onClick={onClose}>
        X
      </span>
      <div className="wrapper">
        <LogoWrapper>
          <img src={brand_image} alt="res_logo" />
        </LogoWrapper>
      </div>
      <span className="menu">
        <p
          onClick={() => {
            navigate("/");
            onClose();
          }}
        >
          HOME
        </p>

        <p
          onClick={() => {
            setshowChild(!showChild);
          }}
        >
          MENUS{" "}
          {showChild ? (
            <i className="bx bx-chevron-up"></i>
          ) : (
            <i className="bx bx-chevron-down"></i>
          )}
        </p>
        {showChild && (
          <div className="child_menu w-75">
            <p
              onClick={() => {
                navigate("/menu-lists", {
                  state: { menu_name: MENUS_TYPE.breakFast },
                });
                onClose();
              }}
            >
              Brekefast
            </p>
            <p
              onClick={() => {
                navigate("/menu-lists", {
                  state: { menu_name: MENUS_TYPE.mainCourse },
                });
                onClose();
              }}
            >
              Main Courses
            </p>
            <p
              onClick={() => {
                navigate("/menu-lists", {
                  state: { menu_name: MENUS_TYPE.snacks },
                });
                onClose();
              }}
            >
              Snacks/Appetizer
            </p>
            <p
              onClick={() => {
                navigate("/menu-lists", {
                  state: { menu_name: MENUS_TYPE.desserst },
                });
                onClose();
              }}
            >
              Desserts
            </p>
            <p
              onClick={() => {
                navigate("/menu-lists", {
                  state: { menu_name: MENUS_TYPE.drinks },
                });
                onClose();
              }}
            >
              Drinks
            </p>
          </div>
        )}

        <p
          onClick={() => {
            navigate("/about-us");
            onClose();
          }}
        >
          ABOUT US
        </p>

        <p
          onClick={() => {
            navigate("/contact-us");
            onClose();
          }}
        >
          CONTACT
        </p>
      </span>{" "}
    </MobileNavMenu>
  );
};

export default MobileSidemenu;
